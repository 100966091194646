<template>
    <div>123</div>
</template>

<script>
export default {
    props: {
        openTableSettings: {
            type: Function,
            default: () => {}
        },
        record: {
            type: Object,
            required: true
        },
        pageName: String,

    }
}
</script>